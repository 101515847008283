import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["proof", "hide"];
  connect() {
    console.log("Hello World from hello controller!");
    this.proofTarget.hidden = false;
    this.hideTarget.hidden = true;
  }
}
