import { Controller } from "@hotwired/stimulus";

// This controller is responsible for handling form submission
// It adds a loading state to the form and submit buttons when the form is submitted
// It also listens to turbo:submit-start and turbo:submit-end events if the form is a Turbo form
// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["submit"];

  connect() {
    this.verifyControlledTarget();
    this.isTurboForm = this.detectTurboForm();
    this.addEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  verifyControlledTarget() {
    if (this.element.tagName === "FORM") {
      return;
    }

    throw new Error("The controlled element must be a form");
  }

  detectTurboForm() {
    return this.element.dataset.turbo !== "false";
  }

  toggleLoadingState() {
    const isLoading = this.element.classList.contains("loading");

    this.element.classList.toggle("loading", !isLoading);

    this.submitTargets.forEach((submit) => {
      submit.disabled = !isLoading;
      submit.classList.toggle("loading", !isLoading);
    });
  }

  onFormSubmitStart = (event) => {
    this.toggleLoadingState();
  };

  onFormSubmitEnd = (event) => {
    this.toggleLoadingState();
  };

  addEventListeners() {
    if (this.isTurboForm) {
      this.element.addEventListener("turbo:submit-start", this.onFormSubmitStart);
      this.element.addEventListener("turbo:submit-end", this.onFormSubmitEnd);
    } else {
      this.element.addEventListener("submit", this.onFormSubmitStart);
    }
  }

  removeEventListeners() {
    if (this.isTurboForm) {
      this.element.removeEventListener("turbo:submit-start", this.onFormSubmitStart);
      this.element.removeEventListener("turbo:submit-end", this.onFormSubmitEnd);
    } else {
      this.element.removeEventListener("submit", this.onFormSubmitStart);
    }
  }
}
